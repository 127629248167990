export const PageSettings = [
  {
    _id: '1',
    pageName: 'Home',
    title1: 'Our Vision',
    description1: 'To bring pieces of Paradise to Earth.',
    title2: 'Our Mision',
    description2:
      'Build software technology that helps making our customers lifes easier and happier, and also provide expert advise about software development processes. Create the best photos that inspire joy and peace in our customers. Be the best host of houses and apparments for rent.',
    title3: 'Our Strategy',
    description3: [
      {
        descId: 1,
        desc: 'Kuarsis Tech: will bring Paradise to Earth by creating Software Technology that helps customers making a complex, stressful, sad life be simpler, relaxed and happier.',
      },
      {
        descId: 2,
        desc: 'Pixan Company: will bring joy, peace and happiness from Paradise to Earth by creating photos and images.',
      },
      {
        descId: 3,
        desc: 'Taanah Company: will bring relaxation and comfort from Paradise to Earth by providing houses and apparments for rent',
      },
    ],
    sidebar: [
      {
        sidebarId: 1,
        sidebarText:
          'In paradise there is only joy and happiness, no suffering exist. Henry Ford brought the car from paradise to Earth, as a result we suffer less while traveling long distances. Kuarsis mission is to keep bringing small pieces of Paradise to Earth.',
      },
    ],
    linkToCompanyWebsite: '',
    settings: [
      {
        priority: 1,
        label: 'Kuarsis Companies',
        subLabel: 'Kuarsis-Tech | Pixan | Taanah',
        imageSrc: './images/KuarsisLogo256px.png',
        alt: 'Kuarsis Companies Image',
        height: '425px',
        width: '425px',
      },
      {
        priority: 2,
        label: 'Kuarsis-Tech',
        subLabel:
          'Software Development, IT Project Management and Scrum Framework',
        imageSrc:
          './images/KuarsisTechHome1radowan-nakif-rehan-cYyqhdbJ9TI-unsplash.jpg',
        alt: 'Kuarsis-Tech image',
        height: '427px',
        width: '100%',
      },
      {
        priority: 3,
        label: 'Pixan',
        subLabel: 'Photography stock for your business or enjoyment.',
        imageSrc:
          './images/PixanHome-2020509-NewportBridgeSunrise-Toma2_SURREAL_VERSION1.0.jpg',
        alt: 'Kuarsis-Pixan Image',
        height: '427px',
        width: '100%',
      },
      {
        priority: 4,
        label: 'Taanah',
        subLabel: 'Houses or rooms for rent.',
        imageSrc:
          './images/TaanahHome-alberto-castillo-q-mx4mSkK9zeo-unsplash.jpg',
        alt: 'Kuarsis-Taanah image',
        height: '427px',
        width: '100%',
      },
    ],
  },
  {
    _id: '2',
    pageName: 'Kuarsis-Tech',
    title1: 'Our Vision',
    description1: 'Be the best software development company on Earth.',
    title2: 'Our Mision',
    description2:
      'Develop high quality software that makes people lifes easier and happier by eliminating the non-sense annoyanses from the world. Provide expert advisory about software development, IT project management and Scrum Frameworks for Agile Software Development.',
    title3: 'Our Strategy',
    description3: [
      {
        descId: 1,
        desc: 'To bring Paradise to Earth by providing expert advisory in software development, IT project management and Scrum Framework.',
      },
      {
        descId: 2,
        desc: 'Making people lives easier by developing Software.',
      },
    ],
    sidebar: [
      {
        sidebarId: 1,
        sidebarText:
          'To see our software offering go to the Tech link on the menu above. If you need guidance about software development, IT project management or scrum framework, you can request expert advisory on a pay-per-hour basis.',
      },
      {
        sidebarId: 2,
        sidebarText:
          'Kuarsis-Tech is always developing new Software for our customers. I you like this website you rent it and we can customize it to your needs',
      },
    ],
    linkToCompanyWebsite: '/kuarsistech',
    settings: [
      {
        priority: 1,
        label: 'Kuarsis-Tech',
        subLabel: 'Software Development and IT management, made simple.',
        imageSrc: './images/KuarsisLogo256px.png',
        alt: 'Kuarsis-Tech image',
        height: '425px',
        width: '425px',
      },
      {
        priority: 2,
        label: 'Kuarsis-Tech Software Development',
        subLabel: 'Innovative software solutions for common annoying problems.',
        imageSrc:
          './images/1-OverwhelmedBySoftware-PhotobyThisisEngineering_RAEngonUnsplash.jpg',
        alt: 'Kuarsis-Tech Overwhelmed by Software image',
        height: '390px',
        width: '100%',
      },
      {
        priority: 3,
        label: 'Kuarsis-Tech Expert software development advisory',
        subLabel:
          'Software Development Process, IT Project Management, IT Scrum Framework.',
        imageSrc:
          './images/2-TechnologyStrategyPhotobyJESHOOTS.COMonUnsplash.jpg',
        alt: 'Kuarsis-Tech Strategy image',
        height: '390px',
        width: '100%',
      },
    ],
  },
  {
    _id: '3',
    pageName: 'Pixan',
    title1: 'Our Vision',
    description1:
      'Bringing Paradise to Earth by creating photos and images that inspire joy, relaxation and happiness in our customers.',
    title2: 'Our Mision',
    description2:
      'Creating inspiring and original photos for your business or enjoyment.',
    title3: 'Our Strategy',

    description3: [
      {
        descId: 1,
        desc: 'Kuarsis will create photographs and images from around the world, our customers can purchase licenses to use the photos for personal or business needs.',
      },
    ],
    sidebar: [
      {
        sidebarId: 1,
        sidebarText:
          'Pixan offers photos of sunsets, sunrises, bridges, traveling, nighscapes, seascapes and more.',
      },
    ],
    linkToCompanyWebsite: '/pixan',
    settings: [
      {
        priority: 1,
        label: 'Pixan',
        subLabel: 'Photography stock online store.',
        imageSrc:
          './images/PixanHome-2020509-NewportBridgeSunrise-Toma2_SURREAL_VERSION1.0.jpg',
        alt: 'Kuarsis-Pixan Image',
        height: '390px',
        width: '100%',
      },
      {
        priority: 2,
        label: 'Pixan',
        subLabel:
          'Nightscapes, Landscapes, Sunsets, Sunrises, Beaches, Bridges, Mansions and more.',
        imageSrc: './images/PixanRebelSpirit.jpg',
        alt: 'Kuarsis-Pixan Image',
        height: '390px',
        width: '100%',
      },
    ],
  },
  {
    _id: '4',
    pageName: 'Taanah',
    title1: 'Our Vision',
    description1:
      'Bringing Paradise to Earth by providing relaxing and confortable house and rooms for rent.',
    title2: 'Our Mision',
    description2:
      'Being the best host in the world for guests renting a house or rooms.',
    title3: 'Our Strategy',

    description3: [
      {
        descId: 1,
        desc: 'Renting our house Casa del Nogal.',
      },
      {
        descId: 2,
        desc: 'Accquiring new houses to rent.',
      },
    ],
    sidebar: [
      {
        sidebarId: 1,
        sidebarText:
          'Traveling to Monterrey, Nuevo Leon for business or leisure?',
      },
      {
        sidebarId: 2,
        sidebarText: 'Casa del Nogal is for you! Book now thru Taanah!',
      },
    ],
    linkToCompanyWebsite: '/kuarsistaanah',
    settings: [
      {
        priority: 1,
        label: 'Taanah',
        subLabel: 'Renting a house or rooms for business or leisure.',
        imageSrc: './images/KuarsisLogo256px.png',
        alt: 'Kuarsis-Taanah Logo',
        height: '425px',
        width: '425px',
      },
      {
        priority: 2,
        label: 'Looking for a wonderful house or room for rent?',
        subLabel: 'For business or leisure, Taanah is the best host.',
        imageSrc: './images/Taanah1-laurentiu-morariu-m49NDxxGR0U-unsplash.jpg',
        alt: 'Kuarsis-Taanah Place to Stay',
        height: '390px',
        width: '100%',
      },
      {
        priority: 3,
        label: 'Taanah is now renting "Casa del Nogal"',
        subLabel:
          '3 rooms, 2.5 restrooms, kitchen and living room. Rent full house or private rooms.',
        imageSrc: './images/Taanah2-anthony-rooke--NJO7AF0mUo-unsplash.jpg',
        alt: 'Kuarsis-Taanah Casa del Nogal',
        height: '390px',
        width: '100%',
      },
    ],
  },
]
